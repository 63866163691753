<template>
  <v-container fluid>
    <v-card flat color="transparent" class="pa-sm-4">
      <v-card-title class="primary--text">
        Edit Category
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text class="mt-6">
        <v-form ref="editForm" @submit.prevent>
          <v-row>
            <v-col cols="12" md="6">
              <v-skeleton-loader
                class="my-6"
                type="image"
                height="50"
                v-if="dataLoading"
              ></v-skeleton-loader>
              <v-file-input
                v-else
                flat
                outlined
                accept="image/png, image/jpeg, image/jpg,  image/bmp"
                prepend-icon="mdi-camera"
                label="Select an image"
                @change="onFileChange"
                v-model="image"
                show-size
              ></v-file-input>
              <v-skeleton-loader
                class="my-6"
                type="image"
                v-if="dataLoading"
              ></v-skeleton-loader>
              <v-img
                v-if="imagePreview"
                :src="imagePreview"
                max-width="100%"
                contain
              ></v-img>
            </v-col>
            <v-col cols="12" md="6">
              <v-skeleton-loader
                class="my-6"
                type="image"
                height="50"
                v-if="dataLoading"
              ></v-skeleton-loader>
              <v-text-field
                v-else
                flat
                outlined
                label="Category Name"
                v-model="item.name"
                :rules="[rules.required]"
              ></v-text-field>
              <v-skeleton-loader
                class="my-6"
                type="image"
                height="50"
                v-if="dataLoading"
              ></v-skeleton-loader>
              <v-textarea
                v-else
                flat
                outlined
                label="Description"
                v-model="item.description"
                :rules="[rules.required]"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-title v-if="!dataLoading">
        <v-spacer></v-spacer>
        <v-btn color="secondary" depressed @click="goBack" text class="mx-3">
          cancel
        </v-btn>
        <v-btn
          color="primary"
          depressed
          @click="editCategoryX"
          :loading="loading"
        >
          Update
        </v-btn>
      </v-card-title>
    </v-card>
  </v-container>
</template>
    
    
    <script>
import { mapState } from "vuex";
import { editCategory, getCategory } from "@/services/categoriesService";
import { uploadDocument } from "@/services/uploadDocumentsService";
export default {
  components: {
    //
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  data: () => ({
    loading: false,
    dataLoading: false,
    rules: {
      required: (value) => !!value || "Required.",
      fileSize: (value) => {
        if (!value) return true;
        return (
          value.size <= 500 * 1024 || "File size must be less than 500 KB."
        );
      },
    },
    item: {
      id: "",
      name: "",
      image: "",
      description: "",
      is_enabled: true,
    },
    image: null,
    imagePreview: null,
  }),
  async created() {
    this.dataLoading = true;
    this.getCategoryReturn = await getCategory(this.$route.params.id);
    this.item = this.getCategoryReturn;
    this.imagePreview = this.item.image;
    this.dataLoading = false;
  },
  mounted() {
    //
  },
  methods: {
    goBack() {
      this.$router.push({
        name: "PageViewCategory",
        params: { id: this.item.id },
        query: { bc: this.item.name },
      });
    },
    async editCategoryX() {
      if (this.$refs.editForm.validate()) {
        this.loading = true;
        if (this.image) {
          const formData = new FormData();
          formData.append("file", this.image);
          let upladDocumentReturn = await uploadDocument("category", formData);
          this.item.image = upladDocumentReturn.document_url;
        }
        let editCategoryReturn = await editCategory(this.item);
        this.loading = false;
        if (editCategoryReturn == "success") {
          this.goBack();
        }
      }
    },
    onFileChange(file) {
      this.selectedFile = file;
      if (file) {
        // Create a temporary URL to show the image preview
        this.imagePreview = URL.createObjectURL(file);
      } else {
        this.imagePreview = null;
      }
    },
  },
};
</script>